import React, { useEffect, useState } from 'react'
import productStore from './stores/product'
import notificationStore from './stores/notification';
import transactionStore from './stores/transaction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Modal from './components/Modal'
import { Text, Boolean } from './components/Fields'
import Button from './components/Button'
import Table from './components/Table'
import numeral from 'numeral'
import SortableHeader from './components/SortableHeader'

const ProductRow = ({product}) => {
  const [addToTransaction] = transactionStore(state => [state.add])
  const [updateProduct, updateFilter, archiveProduct] = productStore(state => [state.update, state.updateFilter, state.archive])
  const [editing, setEditing] = useState(false)
  const [productToEdit, setProductToEdit] = useState({})
  const [archiving, setArchiving] = useState(false)
  function cancel(e) {
    e.preventDefault()
    setEditing(false)
  }
  async function save(e) {
    e.preventDefault()
    await updateProduct(productToEdit)
    setEditing(false)
  }
  function setExactFilter(e) {
    e.preventDefault()
    updateFilter('parent_title', '=' + product.parent_title)
  }
  function edit(e) {
    e.preventDefault()
    setProductToEdit(product)
    setArchiving(false)
    setEditing(true)
  }
  async function archive(e) {
    e.preventDefault()
    await archiveProduct(product)
    setEditing(false)
  }

  return (
    <Table.Row>
      <Table.Td>{product.magento_id}</Table.Td>
      <Table.Td>
        <a onClick={setExactFilter}>
          {product.parent_title}
        </a>
      </Table.Td>
      <Table.Td>{product.sku}</Table.Td>
      <Table.Td>{product.title}</Table.Td>
      <Table.Td className="text-right">{product.notice_threshold}</Table.Td>
      <Table.Td className="text-right">{product.quantity}</Table.Td>
      <Table.Td className="text-right">{product.on_order}</Table.Td>
      <Table.Td className="text-right">
        {product.swag === true && "yes"}
        {product.swag === false && "no"}
      </Table.Td>
      <Table.Td className="text-right">{product.price && numeral(product.price).format('$0,0.00')}</Table.Td>
      <Table.Td className="text-right">{product.cost && numeral(product.cost).format('$0,0.00')}</Table.Td>
      <Table.Td>
        <Button
          onClick={edit}
          block={true}>
          <FontAwesomeIcon icon={icon({name: 'pencil'})} className="mr-2" />
          Edit
        </Button>
        <Modal isOpen={editing} onClose={() => setEditing(false)}>
          <Modal.Header onClose={() => setEditing(false)}>
            Editing {product.title}
          </Modal.Header>
          <Modal.Body>
            <Text
              label="Threshold"
              value={productToEdit.notice_threshold ?? ''}
              onChange={e => setProductToEdit({...productToEdit, notice_threshold: e.target.value})}
              groupClass="mb-4"
            />
            <Text
              label="Cost"
              value={productToEdit.cost ?? ''}
              onChange={e => setProductToEdit({...productToEdit, cost: e.target.value})}
              groupClass="mb-4"
            />
            <Boolean
              label="Swag"
              value={productToEdit.swag ?? ''}
              onChange={e => setProductToEdit({...productToEdit, swag: e.target.value == "true"})}
              groupClass="mb-4"
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center justify-between gap-4">
              {product.archive ?
                <>
                  {archiving ?
                    <Button onClick={archive}>
                      <FontAwesomeIcon icon={icon({name: 'question'})} className="mr-2" />
                      Are you sure?
                    </Button>
                    :
                    <Button onClick={e => setArchiving(true)}>
                      <FontAwesomeIcon icon={icon({name: 'trash-undo'})} className="mr-2" />
                      Restore
                    </Button>
                  }
                </>
                :
                <>
                  {archiving ?
                    <Button type="danger" onClick={archive}>
                      <FontAwesomeIcon icon={icon({name: 'question'})} className="mr-2" />
                      Are you sure?
                    </Button>
                    :
                    <Button type="danger" onClick={e => setArchiving(true)}>
                      <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
                      Archive
                    </Button>
                  }
                </>
              }
              <div className="flex items-center justify-between gap-4">
                <a href="#" onClick={cancel}>
                  Cancel
                </a>
                <Button type="success" onClick={save}>
                  <FontAwesomeIcon icon={icon({name: 'save'})} className="mr-2" />
                  Save
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </Table.Td>
      <Table.Td>
        <Button
          onClick={() => addToTransaction(product)}
          type="success"
          block={true}>
          <FontAwesomeIcon icon={icon({name: 'plus'})} className="mr-2" />
          Add
        </Button>
      </Table.Td>
    </Table.Row>
  )
}

export default () => {
  const [editing, setEditing] = useState(false)
  const [noticeThreshold, setNoticeThreshold] = useState('')
  const [cost, setCost] = useState('')
  const [swag, setSwag] = useState('')
  const [archiving, setArchiving] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [
    fetchProducts,
    filters,
    updateFilter,
    filteredProducts,
    updateProduct,
    archiveProduct,
    setSort,
    currentSortKey,
    sortDirection,
  ] = productStore((state) => [
    state.fetch,
    state.filters,
    state.updateFilter,
    state.filtered,
    state.update,
    state.archive,
    state.setSort,
    state.sortKey,
    state.sortDirection,
  ]);
  const [addNotification] = notificationStore((state) => [state.add]);
  const [
    addToTransaction,
  ] = transactionStore((state) => [
    state.add,
  ]);
  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);
  function addAll() {
    filteredProducts().forEach(product => addToTransaction(product))
  }
  async function updateProducts() {
    await filteredProducts().forEach(product => {
      const newProduct = {...product}
      if (noticeThreshold) {
        newProduct.notice_threshold = noticeThreshold
      }
      if (cost) {
        newProduct.cost = cost
      }
      newProduct.swag = swag
      updateProduct(newProduct)
    })
    setEditing(false)
  }
  function edit() {
    setArchiving(false)
    setEditing(true)
  }
  async function archive() {
    await filteredProducts().forEach(product => {
      archiveProduct(product)
    })
    setEditing(false)
  }
  async function magentoSync() {
    setIsSyncing(true)
    const response = await fetch('/api/products/pull')
    setIsSyncing(false)
    if (response.ok) {
      addNotification({message: 'Products sync started', type: 'success'})
      fetchProducts()
      return
    } 
    addNotification({message: 'Error syncing products', type: 'error'})
  }
  return (
    <div>
      <div className="flex justify-between gap-4 mb-4 items-end">
        <div className='flex gap-4'>
          <h1 className="text-4xl">Products</h1>
          <Button onClick={magentoSync} disabled={isSyncing} >
            <FontAwesomeIcon icon={icon({name: 'rotate'})} spin={isSyncing} className="mr-2" />
            Sync Products
          </Button>
        </div>
        <div className="flex justify-center gap-4">
          <label className="flex items-center">
            <input
              type="radio"
              name="archive"
              checked={!filters.archive}
              onChange={e => updateFilter('archive', false)}
            />
            <span className="ml-2">Live</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="archive"
              checked={filters.archive}
              onChange={e => updateFilter('archive', true)}
            />
            <span className="ml-2">Archive</span>
          </label>
        </div>
      </div>
      <Table>
        <Table.Head className="sticky top-0">
          <Table.Row>
            <SortableHeader sortKey="magento_id" sortType="integer" {...{setSort, currentSortKey, sortDirection}}>ID</SortableHeader>
            <SortableHeader sortKey="parent_title" {...{setSort, currentSortKey, sortDirection}}>Parent</SortableHeader>
            <SortableHeader sortKey="sku" {...{setSort, currentSortKey, sortDirection}}>SKU</SortableHeader>
            <SortableHeader sortKey="title" {...{setSort, currentSortKey, sortDirection}}>Name</SortableHeader>
            <SortableHeader sortKey="notice_threshold" sortType="integer" {...{setSort, currentSortKey, sortDirection}}>Threshold</SortableHeader>
            <SortableHeader sortKey="quantity" sortType="integer" {...{setSort, currentSortKey, sortDirection}}>Quantity</SortableHeader>
            <SortableHeader sortKey="on_order" sortType="integer" {...{setSort, currentSortKey, sortDirection}}>On Order</SortableHeader>
            <SortableHeader sortKey="swag" {...{setSort, currentSortKey, sortDirection}}>Swag</SortableHeader>
            <Table.Th>Price</Table.Th>
            <Table.Th>Cost</Table.Th>
            <Table.Th width="145">
              <Button
                onClick={edit}
                block={true}>
                <FontAwesomeIcon icon={icon({name: 'pencil'})} className="mr-2" />
                Edit All
              </Button>
            </Table.Th>
            <Table.Th width="145">
              <Button
                onClick={addAll}
                type="success"
                block={true}>
                <FontAwesomeIcon icon={icon({name: 'plus'})} className="mr-2" />
                Add All
              </Button>
            </Table.Th>
          </Table.Row>
          <Table.Row>
            <Table.Th>
              <Text
                type="number"
                value={filters.magento_id ?? ''}
                onChange={e => updateFilter('magento_id', e.target.value)}
              />
            </Table.Th>
            <Table.Th>
              <Text
                value={filters.parent_title ?? ''}
                onChange={e => updateFilter('parent_title', e.target.value)}
              />
            </Table.Th>
            <Table.Th>
              <Text
                value={filters.sku ?? ''}
                onChange={e => updateFilter('sku', e.target.value)}
              />
            </Table.Th>
            <Table.Th>
              <Text
                value={filters.title ?? ''}
                onChange={e => updateFilter('title', e.target.value)}
              />
            </Table.Th>
            <Table.Th>
              <Text
                value={filters.notice_threshold ?? ''}
                onChange={e => updateFilter('notice_threshold', e.target.value)}
              />
            </Table.Th>
            <Table.Th>
              <Text
                value={filters.quantity ?? ''}
                onChange={e => updateFilter('quantity', e.target.value)}
              />
            </Table.Th>
            <Table.Th>
              <Text
                value={filters.on_order ?? ''}
                onChange={e => updateFilter('on_order', e.target.value)}
              />
            </Table.Th>
            <Table.Th></Table.Th>
            <Table.Th>{numeral(filteredProducts().reduce((total, p) => total + parseFloat(p.price ?? '0') * p.quantity, 0)).format('$0,0.00')}</Table.Th>
            <Table.Th>{numeral(filteredProducts().reduce((total, p) => total + parseFloat(p.cost ?? '0') * p.quantity, 0)).format('$0,0.00')}</Table.Th>
            <Table.Th></Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {filteredProducts().map((product) => (
            <ProductRow product={product} key={product.id} />
          ))}
        </Table.Body>
      </Table>
      <Modal isOpen={editing} onClose={() => setEditing(false)}>
        <Modal.Header onClose={() => setEditing(false)}>
          Editing {filteredProducts().length} products
        </Modal.Header>
        <Modal.Body>
          <Text
            label="Threshold"
            value={noticeThreshold}
            onChange={e => setNoticeThreshold(e.target.value)}
            groupClass="mb-4"
          />
          <Text
            label="Cost"
            value={cost}
            onChange={e => setCost(e.target.value)}
            groupClass="mb-4"
          />
          <Boolean
            label="Swag"
            value={swag}
            onChange={e => setSwag(e.target.value == 'true')}
            groupClass="mb-4"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center justify-between gap-4">
            {filters.archive ?
              <>
                {archiving ?
                  <Button onClick={archive}>
                    <FontAwesomeIcon icon={icon({name: 'question'})} className="mr-2" />
                    Really restore {filteredProducts().length} products?
                  </Button>
                  :
                  <Button onClick={e => setArchiving(true)}>
                    <FontAwesomeIcon icon={icon({name: 'trash-undo'})} className="mr-2" />
                    Restore
                  </Button>
                }
              </>
              :
              <>
                {archiving ?
                  <Button type="danger" onClick={archive}>
                    <FontAwesomeIcon icon={icon({name: 'question'})} className="mr-2" />
                    Really archive {filteredProducts().length} products?
                  </Button>
                  :
                  <Button type="danger" onClick={e => setArchiving(true)}>
                    <FontAwesomeIcon icon={icon({name: 'trash'})} className="mr-2" />
                    Archive
                  </Button>
                }
              </>
            }
            <div className="flex items-center justify-between gap-4">
              <a href="#" onClick={() => setEditing(false)}>
                Cancel
              </a>
              <Button type="success" onClick={updateProducts}>
                <FontAwesomeIcon icon={icon({name: 'save'})} className="mr-2" />
                Save
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
